<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card>
      <b-row>
        <!-- range -->
        <b-col md="3">
          <b-form-group
            label-for="default-language"
            label="Date Range"
          >
            <flat-pickr
              v-model="rangeDate"
              class="form-control"
              placeholder="Date Range"
              :config="{ mode: 'range'}"
              :disable-mobile="true"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-for="default-language"
            label="When"
          >
            <v-select
              id="vue-select"
              v-model="selected"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="whenOption"
              placeholder="placeholder"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-for="event-importance"
            label="Event Importance"
          >
            <multiselect
              id="event-importance"
              v-model="multiSelected"
              :options="options"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Event Importance"
              label="name"
              track-by="name"
              :preselect-first="false"
              :taggable="false"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              >
                <span
                  v-if="values.length &amp;&amp; !isOpen"
                  class="multiselect__single"
                >
                  {{ values.length }} options selected</span>
                <span v-else-if="values.length" />
                <span v-else-if="values.length !==0 && isOpen">
                  {{ values.length }} options selected
                </span>
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-for="currencies"
            label="Currencies"
          >
            <multiselect
              id="currencies"
              v-model="currenciesSelected"
              :options="currencies"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Currencies"
              label="name"
              track-by="name"
              :preselect-first="false"
              :taggable="false"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              >
                <span
                  v-if="values.length &amp;&amp; !isOpen"
                  class="multiselect__single"
                >
                  {{ values.length }} options selected</span>
                <span v-else-if="values.length" />
                <span v-else-if="values.length !==0 && isOpen">
                  {{ values.length }} options selected
                </span>
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-for="country"
            label="Countries"
          >
            <v-select
              id="countries"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :options="countries"
              placeholder="Countries"
              autocomplete="nope"
            >
              <template
                slot="option"
                slot-scope="option"
              >
                <img
                  :src="option.image"
                  alt="flag"
                  class="language-image"
                >
                {{ option.name }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <!-- table -->
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :group-options="{
              enabled: true
            }"
            :select-options="{
              enabled: false,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <span v-if="props.column.label ==='Currency'">
                <img
                  :src="props.row.flag"
                  :alt="props.row.currency"
                > {{ props.row.currency }}
              </span>
              <span v-else-if="props.column.label ==='Event'">
                <img
                  :src="props.row.level"
                  :alt="props.row.level"
                  class="small-photo-size"
                > {{ props.row.event }}
              </span>
              <span v-else>
                {{ props.row[props.column.field] }}
              </span>

            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    {{ $t('message.pagelength') }}
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10','15']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BPagination, BFormGroup, BFormSelect, BCol, BCard, BOverlay, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Multiselect from 'vue-multiselect'
import store from '@/store/index'
import { codeMessageBox } from '@/views/components/modal/code'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormSelect,
    BCol,
    BCard,
    BOverlay,
    flatPickr,
    vSelect,
    BRow,
    Multiselect,
  },
  data() {
    return {
      multiSelected: [],
      options: [
        { name: 'Holidays' },
        { name: 'Low' },
        { name: 'Medium' },
        { name: 'High' },
      ],
      currencies: [
        {
          name: 'AUD - Australian Dollar',
        },
        {
          name: 'BRL - Brazilian real',
        },
        {
          name: 'CAD - Canadian dollar',
        },
        {
          name: 'CHF - Swiss frank',
        },
      ],
      currenciesSelected: [],
      pageLength: 15,
      show: true,
      dir: false,
      codeMessageBox,
      boxOne: '',
      boxTwo: '',
      columns: [
        {
          label: 'Time',
          field: 'time',
        },
        {
          label: 'Currency',
          field: 'currency',
        },
        {
          label: 'Event',
          field: 'event',
        },
        {
          label: 'Actual',
          field: 'actual',
        },
        {
          label: 'Forecast',
          field: 'forecast',
        },
        {
          label: 'Previous',
          field: 'previous',
        },
      ],
      rows: [],
      searchTerm: '',
      rangeDate: null,
      selected: '',
      whenOption: ['Current Week', 'Previous week', 'Next week', 'Current month', 'Previous month', 'Next month'],
    }
  },
  computed: {
    ...mapGetters(['getEconomic', 'getCountries']),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    countries() {
      return this.getCountries
    },
  },
  async created() {
    await this.getEconomicCalendarData()
    if (this.getEconomic) {
      this.rows = JSON.parse(JSON.stringify(this.getEconomic))
    }
    this.show = false
  },
  methods: {
    ...mapActions(['getEconomicCalendarData']),
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
